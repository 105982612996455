@use 'mixins/breakpoints';

app-styled-input {
  display: flex;
  flex-direction: column;
  gap: .8rem;
  position: relative;

  .errors {
    font-size: 1.4rem;
    color: var(--error);
  }

  .success {
    font-size: 1.4rem;
    color: var(--success);
  }

  input {
    height: 4.6rem;
  }

  .input-wrapper {
    position: relative;

    .close {
      position: absolute;
      right: 1.2rem;
      top: 50%;
      transform: translateY(-50%);
      width: fit-content;
      cursor: pointer;
    }
  }

  input, textarea {
    padding: 1.2rem;
    outline: none;
    border-radius: 4px;
    border: 1px solid var(--primary-15);
    background: var(--white);
    width: 100%;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 114%; /* 18.24px */
    letter-spacing: -0.32px;
    display: flex;

    &::placeholder {
      color: var(--primary-20);
    }

    &:focus {
      border: 1px solid var(--primary-40);
    }

    &.error {
      border: 1px solid var(--error);
    }

    &.with-close {
      padding-right: 3.6rem;
    }
  }



  @include breakpoints.screen(md) {
    input {
      height: 4.2rem;
    }
  }
}


