import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-styled-input',
    imports: [],
    templateUrl: './styled-input.component.html',
    styleUrl: './styled-input.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class StyledInputComponent {

}
